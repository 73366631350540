$primary: #333;
$secondry: #444;

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  text-align: center;
  background-color: $secondry;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  background-color: #222;
}

.headerTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header .buttonContainer {
  background-color: $primary;
}

.header .buttonContainer a {
  background-color: #222;
  margin: 0.2em;
  text-decoration: none;
}

.content {
  background-color: $secondry;
}

.footer {
  width: 100%;
  height: 6em;
  background-color: $primary;
}